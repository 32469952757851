import {
  BillImagesFragment,
  useUpdateBillImagesMutation,
} from "../generated/graphql";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  Paper,
} from "@mui/material";
import { CheckBox as CheckboxIcon } from "@mui/icons-material";
import BillImagesControls from "./BillImagesControls";

const BillImagesComponent = ({
  billImages,
  display,
  selectedImageIndexes,
  setSelectedImageIndexes,
}: {
  billImages: BillImagesFragment;
  display: boolean;
  selectedImageIndexes: number[];
  setSelectedImageIndexes: Dispatch<SetStateAction<number[]>>;
}) => {
  const [isEOB, setIsEOB] = useState<boolean>(billImages.isEOB ?? false);
  const [updateBillImagesMutation, { loading, error }] =
    useUpdateBillImagesMutation();

  const processBillImages = useCallback(async () => {
    await updateBillImagesMutation({
      variables: {
        userId: billImages.userId,
        billImagesId: billImages.id,
        billImagesInput: {
          isEOB,
          isProcessed: true,
        },
      },
    });
  }, [isEOB, billImages, updateBillImagesMutation]);

  return (
    <Paper
      className="scrollable-image-display"
      style={{
        width: "50%",
        display: display ? "flex" : "none",
      }}
      key={billImages.id}
    >
      <List>
        <ListItem key="title">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap="1rem" alignItems="end">
              <h4 style={{ margin: 0 }}>Submission:</h4>
              <p style={{ margin: 0 }}>
                {billImages?.id?.slice(0, 4)}...
                {billImages?.id?.slice(-5, billImages.id.length)}
              </p>
            </Box>
          </Box>
        </ListItem>
        <ListItem>
          <h5>Images</h5>
        </ListItem>
        <BillImagesControls
          billImages={billImages}
          display={display}
          selectedImageIndexes={selectedImageIndexes}
          setSelectedImageIndexes={setSelectedImageIndexes}
        />
        <ListItem key="isEOB">
          <Box display="flex">
            <span>
              Is EOB?
              <Checkbox
                onClick={() => setIsEOB(!isEOB)}
                disabled={billImages.isProcessed ?? false}
                value={isEOB}
              />
            </span>
            <Alert severity="info">
              Mark as EOB before setting as processed if the bill is an
              Explanation of Benefits
            </Alert>
          </Box>
        </ListItem>
        <ListItem key="processed">
          <Box display="flex">
            <Alert severity="warning">
              Do not mark as processed until all bills have been created for
              this image submission
            </Alert>
            {billImages.isProcessed ? (
              <>
                <CheckboxIcon />
                "️Processed"
              </>
            ) : (
              <Button
                variant="contained"
                type="button"
                color="error"
                onClick={processBillImages}
              >
                Set As Processed
              </Button>
            )}
          </Box>
          {loading && <div>Loading...</div>}
          {error && <div>Error: {error.message}</div>}
        </ListItem>
      </List>
    </Paper>
  );
};

export default BillImagesComponent;
