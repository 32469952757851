import React, { useState, useEffect } from "react";
import {
  BillImagesFragment,
  useGetBillImagesByUserIdQuery,
  useGetUserQuery,
} from "../generated/graphql";
import { Box } from "@mui/material";

const UserSpecificBillImagesListItem = ({ userId }: { userId: string }) => {
  const [billImages, setBillImages] = useState<BillImagesFragment[]>([]);

  const { data } = useGetBillImagesByUserIdQuery({
    variables: {
      userId,
      paginationOptions: {
        limit: 10,
      },
    },
  });
  const userResponse = useGetUserQuery({
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (data && data.getBillImagesByUserId?.billImages) {
      setBillImages(
        data.getBillImagesByUserId?.billImages.filter(
          (billImage) => billImage?.pending === false
        )
      );
    }
  }, [data]);

  return (
    <Box sx={{ m: 10 }}>
      <div key={userId}>
        <h3>Bill Submissions for {userResponse.data?.getUser?.name}</h3>
        <div>
          <h4>Number of submissions: {billImages.length}</h4>
        </div>
      </div>
    </Box>
  );
};

export default UserSpecificBillImagesListItem;
