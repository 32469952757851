import { Box, Button, Checkbox } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import { BillImagesFragment } from "../generated/graphql";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const style = {};

const BillImagesControls = ({
  billImages,
  selectedImageIndexes,
  setSelectedImageIndexes,
  display,
  hideProcessed = true,
}: {
  billImages: BillImagesFragment;
  selectedImageIndexes: number[];
  setSelectedImageIndexes: Dispatch<SetStateAction<number[]>>;
  display: boolean;
  hideProcessed?: boolean;
}) => {
  const [bigImage, setBigImage] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(false);

  // TODO: this is kind of a smelly way of doing all this,
  //  but is probably the best way until we only render the correct BillImageComponent
  const processedIndexList =
    billImages.processedKeys?.map((key) => billImages.keys.indexOf(key)) ?? [];

  useEffect(() => {
    if (display) {
      setSelectedImageIndexes(
        billImages.imageUrls
          .map((img, index) => index)
          .filter((_, index) => !processedIndexList.includes(index))
      );
    }
  }, []); // We need the dependancy array empty, which makes useEffect only run on first mount.

  const selectIndex = (index: number) => {
    return setSelectedImageIndexes(selectedImageIndexes.concat(index));
  };

  const deselectIndex = (index: number) => {
    return setSelectedImageIndexes(
      selectedImageIndexes.filter((idx) => idx !== index)
    );
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        return deselectIndex(index);
      }
      return selectIndex(index);
    };

  return (
    <Box sx={style}>
      <Button onClick={() => setRotation(rotation + 90)}>Rotate</Button>
      <Button onClick={() => setZoom(!zoom)}>Enlarge</Button>
      <Box
        maxHeight="90%"
        display="grid"
        overflow="scroll"
        gridTemplateColumns="repeat(3, 1fr)"
      >
        <Box
          style={{
            gridColumn: `1 / span ${zoom ? "3" : "2"}`,
            gridRow: "1 / span 100",
            margin: "0 1rem 0 0",
            aspectRatio: "1/1",
          }}
        >
          <div>
            <Zoom>
              <img
                onClick={() => setRotation(0)}
                alt="bill scan submission"
                src={billImages.imageUrls[bigImage] ?? ""}
                style={{ width: "100%", transform: `rotate(${rotation}deg)` }}
              />
            </Zoom>
          </div>
        </Box>
        {billImages &&
          billImages.imageUrls &&
          billImages.imageUrls.map((imageUrl, index) => {
            return (
              (!hideProcessed || !processedIndexList.includes(index)) && (
                <Box
                  key={imageUrl}
                  style={{
                    gridColumn: "auto",
                    gridRow: "auto",
                  }}
                >
                  <img
                    onClick={() => setBigImage(index)}
                    alt="bill scan submission"
                    src={imageUrl!}
                    style={{ width: "100%" }}
                  />
                  <span
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <Checkbox
                      checked={selectedImageIndexes.includes(index)}
                      onChange={handleChange(index)}
                    />{" "}
                    Include in bill
                  </span>
                </Box>
              )
            );
          })}
      </Box>
    </Box>
  );
};

export default BillImagesControls;
