import { Box, Button } from "@mui/material";
import {
  GetDocumentsDocument,
  useGetBillImagesByUserIdBillIdQuery,
  useGetDocumentsQuery,
  useUpdateBillImagesMutation,
  useUpdateDocumentMutation,
} from "../../generated/graphql";
import BillImagesControls from "../BillImagesControls";
import { useCallback, useEffect, useState } from "react";
const BillImageOptions = ({
  userId,
  billId,
}: {
  userId: string;
  billId: string;
}) => {
  const [selectedImageIndexes, setSelectedImageIndexes] = useState<number[]>(
    []
  );

  const { data: doc } = useGetDocumentsQuery({
    variables: {
      userId: userId,
      billId: billId,
      paginationOptions: {},
    },
  });
  const { data } = useGetBillImagesByUserIdBillIdQuery({
    variables: {
      userId: userId,
      billId: billId,
      paginationOptions: {},
    },
  });

  const billImage = data?.getBillImagesByUserIdBillId?.billImages?.[0];
  const document = doc?.getDocuments.documents?.[0];

  useEffect(() => {
    setSelectedImageIndexes(
      document?.keys.map((key) => billImage?.keys.indexOf(key)!) || []
    );
  }, [data, doc]);

  const [updateBillImagesMutation] = useUpdateBillImagesMutation();
  const [updateDocumentMutation] = useUpdateDocumentMutation();

  const processBillImages = useCallback(async () => {
    const processedKeys = billImage?.keys.filter((_, index) =>
      _ === null ? false : selectedImageIndexes.includes(index)
    );

    if (!billImage || !document) return null;
    await updateDocumentMutation({
      variables: {
        userId: userId,
        billId: billId,
        documentId: document?.id,
        document: {
          keys: processedKeys as string[],
          s3Bucket: billImage.s3Bucket,
        },
      },
      refetchQueries: [GetDocumentsDocument],
    });

    await updateBillImagesMutation({
      variables: {
        billImagesId: billImage?.id || "",
        userId: userId,
        billImagesInput: {
          isProcessed: true,
          billId: billId,
          processedKeys: processedKeys as string[],
        },
      },
    });
  }, [
    updateBillImagesMutation,
    selectedImageIndexes,
    billImage,
    userId,
    billId,
  ]);

  if (!billImage) return <h1>loading</h1>;
  return (
    <div>
      <p>Images associated with bill</p>
      <Box display="flex" flexWrap="wrap" maxWidth="sm">
        {document?.imageUrls?.map((img, index) => (
          <img key={img} style={{ width: "100%" }} src={img} />
        ))}
      </Box>
      <p>Images from original document</p>
      <BillImagesControls
        display={true}
        hideProcessed={false}
        billImages={billImage}
        selectedImageIndexes={selectedImageIndexes}
        setSelectedImageIndexes={setSelectedImageIndexes}
      />
      <Box display="flex" justifyContent="right">
        <Button onClick={processBillImages} variant="contained" color="error">
          Update document with selected images
        </Button>
      </Box>
    </div>
  );
};

export default BillImageOptions;
