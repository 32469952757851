import { BillImagesFragment, UserFragment } from "../generated/graphql";
import CreateBillForm from "./form/CreateBillForm";
import UpdateBillForm from "./form/UpdateBillForm";
import "react-medium-image-zoom/dist/styles.css";
import { useState } from "react";
import "./BillImageEntryDisplay.css";
import BillImagesComponent from "./BillImagesComponent";

function BillImageEntryDisplay({
  billImages,
  user,
  index,
  userRefetch,
}: {
  billImages: BillImagesFragment[];
  user: UserFragment;
  index: number;
  userRefetch: () => void;
}) {
  const [selectedImageIndexes, setSelectedImageIndexes] = useState<number[]>(
    []
  );
  const [billUpdated, setBillUpdated] = useState<boolean>(false);
  const [updateBillId, setUpdateBillId] = useState<string | null>(null);

  if (!billImages) {
    return <div>Bill Images not found</div>;
  }

  const keysToProcess =
    (billImages[index]?.keys.filter((_, index) =>
      _ === null ? false : selectedImageIndexes.includes(index)
    ) as string[]) ?? [];

  return (
    <div
      style={{ display: "flex", gap: "3rem", padding: "1rem" }}
      key="bill-image-entry-display"
    >
      {billImages &&
        billImages.length > 0 &&
        billImages.map((billImage: BillImagesFragment, i: number) => (
          <BillImagesComponent
            key={billImage.id}
            display={i === index}
            billImages={billImage}
            selectedImageIndexes={selectedImageIndexes}
            setSelectedImageIndexes={setSelectedImageIndexes}
          />
        ))}
      <div style={{ width: "50%", height: "90vh" }}>
        {updateBillId ? (
          <>
            <UpdateBillForm
              billId={updateBillId}
              userId={user.id}
              newBillImageId={
                billImages[index] ? billImages[index].id : undefined
              }
              onBillUpdate={() => {
                setBillUpdated(true);
                setUpdateBillId(null);
                setSelectedImageIndexes([]);
              }}
              onCancelEdit={() => {
                setUpdateBillId(null);
                setBillUpdated(false);
              }}
            />
          </>
        ) : (
          <CreateBillForm
            onBillCreate={() => {
              setSelectedImageIndexes([]);
            }}
            setUpdateBill={(billId) => {
              setUpdateBillId(billId);
              setBillUpdated(false);
            }}
            selectedBillImageKeys={
              billImages[index]?.keys.filter((_, index) =>
                _ === null ? false : selectedImageIndexes.includes(index)
              ) as string[]
            }
            processedKeys={(billImages[index]?.processedKeys ?? []) as string[]}
            setIsProcessed={
              keysToProcess.length +
                (billImages[index]?.processedKeys?.length ?? 0) >=
              billImages[index]?.keys.length
            }
            s3Bucket={billImages[index]?.s3Bucket}
            user={user}
            userRefetch={userRefetch}
            billImagesId={billImages[index] ? billImages[index].id : null}
          />
        )}
        {billUpdated && <div>Bill Updated Successfully</div>}
      </div>
    </div>
  );
}

export default BillImageEntryDisplay;
