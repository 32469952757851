import { useParams } from "react-router-dom";
import React from "react";
import UpdateBillForm from "../components/form/UpdateBillForm";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

function UpdateBill() {
  const params = useParams();
  const billId = params.billId || "test";
  const userId = params.userId || "test";

  return (
    <div>
      <Container
        style={{
          marginTop: 15,
          marginBottom: 15,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/users">
            Users
          </Link>
          <Link underline="hover" color="inherit" href={"/users/" + userId}>
            {userId}
          </Link>
          <Typography color="text.primary">Bills</Typography>
          <Typography color="text.primary">{billId}</Typography>
        </Breadcrumbs>
      </Container>
      <h2>Update Bill {billId}</h2>
      <UpdateBillForm
        billId={billId}
        userId={userId}
        onCancelEdit={() => {
          console.error("NOT IMPLEMENTED");
        }}
      />
    </div>
  );
}

export default UpdateBill;
